define("discourse/plugins/discourse-translator/discourse/components/post-menu/toggle-translation-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _concatClass, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ToggleTranslationButton extends _component.default {
    static shouldRender(args) {
      return args.post.can_translate;
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "translator", [_service.service]))();
    #translator = (() => (dt7948.i(this, "translator"), void 0))();
    get isTranslating() {
      return this.args.post.isTranslating;
    }
    get isTranslated() {
      return this.args.post.isTranslated;
    }
    get showButton() {
      return this.args.post.can_translate;
    }
    get title() {
      if (this.isTranslating) {
        return "translator.translating";
      }
      return this.isTranslated ? "translator.hide_translation" : "translator.view_translation";
    }
    hideTranslation() {
      this.args.post.isTranslated = false;
      this.args.post.isTranslating = false;
      this.translator.clearPostTranslation(this.args.post);
    }
    static #_3 = (() => dt7948.n(this.prototype, "hideTranslation", [_object.action]))();
    toggleTranslation() {
      return this.args.post.isTranslated ? this.hideTranslation() : this.translate();
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleTranslation", [_object.action]))();
    async translate() {
      const post = this.args.post;
      post.isTranslating = true;
      try {
        await this.translator.translatePost(post);
        post.isTranslated = true;
      } catch (error) {
        this.translator.clearPostTranslation(this.args.post);
        post.isTranslated = false;
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        post.isTranslating = false;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "translate", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showButton}}
          <DButton
            class={{concatClass
              "post-action-menu__translate"
              (if this.isTranslated "translated")
            }}
            ...attributes
            @action={{this.toggleTranslation}}
            @disabled={{this.isTranslating}}
            @icon="globe"
            @label={{if @showLabel this.title}}
            @title={{this.title}}
          />
        {{/if}}
      
    */
    {
      "id": "361rKEUY",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showButton\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"post-action-menu__translate\",[52,[30,0,[\"isTranslated\"]],\"translated\"]],null]],[17,1]],[[\"@action\",\"@disabled\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"toggleTranslation\"]],[30,0,[\"isTranslating\"]],\"globe\",[52,[30,2],[30,0,[\"title\"]]],[30,0,[\"title\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&attrs\",\"@showLabel\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-translator/discourse/components/post-menu/toggle-translation-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ToggleTranslationButton;
});