define("discourse/plugins/discourse-translator/discourse/components/show-original-content", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _concatClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ShowOriginalContent extends _component.default {
    static shouldRender(args) {
      return args.topic.is_translated || args.topic.postStream.posts.some(_ref => {
        let {
          is_translated
        } = _ref;
        return is_translated;
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isTranslated", [_tracking.tracked], function () {
      return true;
    }))();
    #isTranslated = (() => (dt7948.i(this, "isTranslated"), void 0))();
    constructor() {
      super(...arguments);
      this.isTranslated = !new URLSearchParams(window.location.search).has("show");
    }
    async showOriginal() {
      const params = new URLSearchParams(window.location.search);
      if (this.isTranslated) {
        params.append("show", "original");
      } else {
        params.delete("show");
      }
      window.location.search = params.toString();
    }
    static #_3 = (() => dt7948.n(this.prototype, "showOriginal", [_object.action]))();
    get title() {
      return this.isTranslated ? "translator.content_translated" : "translator.content_not_translated";
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="discourse-translator_toggle-original">
          <DButton
            @icon="language"
            @title={{this.title}}
            class={{concatClass "btn btn-default" (if this.isTranslated "active")}}
            @action={{this.showOriginal}}
          />
        </div>
      
    */
    {
      "id": "rrrIZrSW",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"discourse-translator_toggle-original\"],[12],[1,\"\\n      \"],[8,[32,0],[[16,0,[28,[32,1],[\"btn btn-default\",[52,[30,0,[\"isTranslated\"]],\"active\"]],null]]],[[\"@icon\",\"@title\",\"@action\"],[\"language\",[30,0,[\"title\"]],[30,0,[\"showOriginal\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-translator/discourse/components/show-original-content.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ShowOriginalContent;
});